import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/security/auth.service';

@Directive({
  selector: '[allowed]',
  standalone: true
})
export class AllowedDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  @Input() set allowed(permissions: string | string[]) {
    if (!permissions || !permissions.length) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    if (typeof permissions === 'string') {
      permissions = [permissions];
    }

    const hasPermission = permissions.some((permission) =>
      this.authService.hasPermission(permission)
    );

    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
